<template>
  <div>
    <b-card>
      <page-title v-if="titleStatus" :title="$t('horses.items')" />
      <main-table
        :fields="fields"
        :list_url="'admin/horse'"
        :reloadData="reloadData"
        :params="filter"
        :createBtnLabel="$t('horses.create')"
        createRouteName="contributor.horseCreate"
        :excelFields="excelFields"
        excelName="horses"
        :downloadSampleStatus="true"
        downloadSampleUrl="horse/excel-sample"
        :uploadFileStatus="true"
        uploadFileUrl="horse/excel"
      />
    </b-card>
  </div>
</template>
<script>
import { core } from '../../../../config/pluginInit'
import HorseFilter from '../models/HorseFilter'
import searchMixin from '../../../../Mixins/searchMixin'

export default {
  mixins: [searchMixin],
  data () {
    return {
      fields: [
        this.$t('global.selected'),
        { key: 'id', label: this.$t('table.id'), sortable: true },
        { key: 'name', label: this.$t('table.name'), sortable: true },
        { key: 'weight', label: this.$t('global.weight'), sortable: true },
        { key: 'owner.name', label: this.$t('owners.item'), sortable: true },
        {
          key: 'trainer.name',
          label: this.$t('trainers.item'),
          sortable: true
        },
        {
          key: 'horseSex.name',
          label: this.$t('horseGenders.item'),
          sortable: true
        },
        {
          key: 'isFavorite',
          label: this.$t('global.isFavorite'),
          type: 'boolean',
          sortable: true
        },
        {
          key: 'actions',
          label: this.$t('table.actions'),
          type: 'actions',
          actions: [
            {
              text: this.$t('global.view'),
              icon: 'fas fa-eye',
              color: 'info',
              ifNavigate: true,
              routeName: 'contributor.horseView',
              routeParams: ['id']
            },
            {
              text: this.$t('global.update'),
              icon: 'fas fa-pen',
              color: 'primary',
              ifNavigate: true,
              routeName: 'contributor.horseUpdate',
              routeParams: ['id']
            },
            {
              text: this.$t('global.delete'),
              icon: 'fas fa-trash-alt',
              color: 'danger',
              showAlert: true,
              actionHeader: this.$t('global.delete'),
              titleHeader: this.$t('horses.item'),
              textContent: 'name',
              url: 'admin/horse',
              type: 'admin/delete'
            }
          ]
        }
      ],
      excelFields: {
        [this.$t('table.id')]: 'id',
        [this.$t('table.name')]: 'name',
        [this.$t('global.birthDate')]: 'birth_date',
        [this.$t('global.weight')]: 'weight',
        [this.$t('owners.item')]: 'owner.name',
        [this.$t('trainers.item')]: 'trainer.name',
        [this.$t('horseGenders.item')]: 'horseSex.name',
        [this.$t('global.isFavorite')]: 'isFavorite'
      },
      filter: new HorseFilter()
    }
  },
  props: {
    ownerId: { default: '' },
    trainerId: { default: '' },
    horseSexId: { default: '' }
  },
  mounted () {
    core.index()
  },
  created () {
    this.filter.owner_id = this.ownerId || null
    this.filter.trainer_id = this.trainerId || null
    this.filter.horse_sex_id = this.horseSexId || null
  }
}
</script>
