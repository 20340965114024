import GeneralFilter from '../../../../Model/GeneralFilter'

export default class HorseFilter extends GeneralFilter {
  constructor () {
    super()
    this.setInitialValue()
  }

  setInitialValue () {
    this.setFilterInitialValue()
    this.owner_id = ''
    this.trainer_id = ''
    this.horse_sex_id = ''
  }
}
